import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import PageTop from "../components/pagetop"

import "./layout.scss"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"
config.autoAddCss = false

const componentsLayout = ({ children }) => (
  <div className="wrapper">
    <Header />

    {children}

    <PageTop />

    <Footer />
  </div>
)

export default componentsLayout