import React from "react"
import { animateScroll as scroll } from "react-scroll"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons"

class PageTop extends React.Component {

  scrollToTop = () => {
    scroll.scrollToTop()
  }

  render() {
    return (
      <div className="pagetop">
        <button className="pagetop__btn" onClick={this.scrollToTop}><FontAwesomeIcon icon={faChevronUp} />トップへ戻る</button>
      </div>
    )
  }
}

export default PageTop