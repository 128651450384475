import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const SearchResult = props => {
  const tempData = useStaticQuery(graphql`
    query SearchData {
      allContentfulBlogPostForSeach: allContentfulBlogPost( sort: {fields: publishDate, order: DESC}) {
        edges {
          node {
            title
            slug
            publishDateJP:publishDate(formatString: "YYYY年MM月DD日")
            publishDate
          }
        }
      }
    }
  `)

  const className = useState("")
  const allPosts = tempData.allContentfulBlogPostForSeach.edges
  const emptyQuery = ""
  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })
  const handleInputChange = event => {
    const query = event.target.value
    const posts = tempData.allContentfulBlogPostForSeach.edges || []

    const filteredData = posts.filter(post => {
      const title = post.node.title
      return (
        title.toLowerCase().includes(query.toLowerCase())
      )
    })
    setState({
      query,
      filteredData,
    })
  }
  const { filteredData, query } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const result = hasSearchResults ? filteredData : allPosts

  return (
    <div className={className}>
      <div className="result-inner">
        <div className="result-content">
          <input
            type="text"
            aria-label="Search"
            placeholder="検索ワードを入力..."
            onChange={handleInputChange}
          />
          <p className="result-inner__res">
            {query !== "" ?
              query + " の検索結果: " + result.length + "件"
              : result.length + "件の記事があります"
            }
          </p>
          <ul className="result-inner__search">
            {result && result.map(({ node: post }) => {
              return (
                <li key={post.slug}>
                  <Link to={`/blog/post/${post.slug}/`}>
                    <span className="result-inner__date">{post.publishDateJP}：</span><span className="result-inner__title">{post.title}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SearchResult